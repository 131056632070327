@font-face {
  font-family: 'NotoSans';
  src: url('../assets/fonts/noto-sans/woff2/RSNotoSans-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'NotoSans';
  src: url('../assets/fonts/noto-sans/woff2/RSNotoSans-Light.woff2') format('woff2');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'NotoSans';
  src: url('../assets/fonts/noto-sans/woff2/RSNotoSans-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'NotoSans';
  src: url('../assets/fonts/noto-sans/woff2/RSNotoSans-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}


/* Add Italic for regular/ligth/medium/bold types. Find that DateInput_input__disabled uses Italic in ./src/index.css file  */
@font-face {
  font-family: 'NotoSans';
  src: url('../assets/fonts/noto-sans/woff2/RSNotoSans-Italic.woff2') format('woff2');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'NotoSans';
  src: url('../assets/fonts/noto-sans/woff2/RSNotoSans-LightItalic.woff2') format('woff2');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'NotoSans';
  src: url('../assets/fonts/noto-sans/woff2/RSNotoSans-MediumItalic.woff2') format('woff2');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'NotoSans';
  src: url('../assets/fonts/noto-sans/woff2/RSNotoSans-BoldItalic.woff2') format('woff2');
  font-weight: 700;
  font-style: italic;
}

/* Japanese */
@font-face {
  font-family: 'NotoSans_JP';
  src: url('../assets/fonts/noto-sans-others/NotoSansJP-VariableFont_wght.woff2') format('woff2');
  font-weight: 200 700;
  font-style: normal;
}

/* Arabic */
@font-face {
  font-family: 'NotoSans_Arabic';
  src: url('../assets/fonts/noto-sans-others/NotoSansArabic-VariableFont_wdth_wght.woff2') format('woff2');
  font-weight: 200 700;
  font-style: normal;
}

/* Chinese: Simple (SC) + Traditional (TC) */
@font-face {
  font-family: 'NotoSans_SC';
  src: url('../assets/fonts/noto-sans-others/NotoSansSC-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'NotoSans_SC';
  src: url('../assets/fonts/noto-sans-others/NotoSansSC-Light.woff2') format('woff2');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'NotoSans_SC';
  src: url('../assets/fonts/noto-sans-others/NotoSansSC-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'NotoSans_SC';
  src: url('../assets/fonts/noto-sans-others/NotoSansSC-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'NotoSans_TC';
  src: url('../assets/fonts/noto-sans-others/NotoSansTC-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'NotoSans_TC';
  src: url('../assets/fonts/noto-sans-others/NotoSansTC-Light.woff2') format('woff2');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'NotoSans_TC';
  src: url('../assets/fonts/noto-sans-others/NotoSansTC-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'NotoSans_TC';
  src: url('../assets/fonts/noto-sans-others/NotoSansTC-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}

/* Korean */
@font-face {
  font-family: 'NotoSans_KR';
  src: url('../assets/fonts/noto-sans-others/NotoSansKR-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'NotoSans_KR';
  src: url('../assets/fonts/noto-sans-others/NotoSansKR-Light.woff2') format('woff2');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'NotoSans_KR';
  src: url('../assets/fonts/noto-sans-others/NotoSansKR-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'NotoSans_KR';
  src: url('../assets/fonts/noto-sans-others/NotoSansKR-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}

/* Hindi */
@font-face {
  font-family: 'NotoSans_Hindi';
  src: url('../assets/fonts/noto-sans-others/NotoSansDevanagari-VariableFont_wdth_wght.woff2') format('woff2');
  font-weight: 200 700;
  font-style: normal;
}

/* Hebrew */
@font-face {
  font-family: 'NotoSans_Hebrew';
  src: url('../assets/fonts/noto-sans-others/NotoSansHebrew-VariableFont_wdth_wght.woff2') format('woff2');
  font-weight: 200 700;
  font-style: normal;
}