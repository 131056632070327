*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  line-height: 1.5;
  font-family: 'NotoSans', 'NotoSans_Hebrew', 'NotoSans_Arabic', 'NotoSans_Hindi', 'NotoSans_JP', 'NotoSans_KR', 'NotoSans_TC', 'NotoSans_SC';
}

p {
  margin-top: 0;
  margin-bottom: 16px;
}

[tabindex='-1']:focus {
  outline: 0 !important;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}

a:hover {
  color: #0056b3;
}
